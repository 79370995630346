// auth reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

// UI REDUCER TYPES
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const SET_SUCCESS = 'SET_SUCCESS';
export const LOADING_DATA = 'LOADING_DATA';
export const STOP_LOADING_DATA = 'STOP_LOADING_DATA';

// SHARED TYPES
export const SET_CURRENT = 'SET_CURRENT';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';

//Staff types
export const ADD_STAFF = 'ADD_STAFF';
export const DELETE_STAFF = 'DELETE_STAFF';
export const SET_STAFF = 'SET_STAFF';
export const LOAD_STAFF = 'LOAD_STAFF';
export const STAFF_ERROR = 'STAFF_ERROR';
export const EDIT_STAFF = 'EDIT_STAFF';
export const UPDATE_STAFF = 'UPDATE_STAFF';

// company types
export const ADD_COMPANY = 'ADD_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const SET_CURRENT_COMPANY = 'SET_CURRENT_COMPANY';
export const LOAD_COMPANY = 'LOAD_COMPANY';
export const EDIT_COMPANY = 'EDIT_COMPANY';

//menu types
export const ADD_MENU = 'ADD_MENU';
export const LOAD_MENU = 'LOAD_MENU';
export const RENAME_MENU = 'RENAME_MENU';
export const DELETE_MENU = 'DELETE_MENU';
export const CUSITOMIZE_COVER = 'CUSITOMIZE_COVER';
export const SET_CURRENT_MENU = 'SET_CURRENT_MENU';
export const CLEAR_CURRENT_MENU = 'CLEAR_CURRENT_MENU';

// category types
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const RENAME_CATEGORY = 'RENAME_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY';
export const CLEAR_CURRENT_CATEGORY = 'CLEAR_CURRENT_CATEGORY';

// types meal
export const ADD_MEAL = 'ADD_MEAL';
export const EDIT_MEAL = 'EDIT_MEAL';
export const DELETE_MEAL = 'DELETE_MEAL';
export const SET_CURRENT_MEAL = 'SET_CURRENT_MEAL';
export const CLEAR_CURRENT_MEAL = 'CLEAR_CURRENT_MEAL';

// types orders
export const ADD_ORDER = 'ADD_ORDER';
export const EDIT_ORDER = 'EDIT_ORDER';
export const FILTER_ORDER = 'FILTER_ORDER';
export const LOAD_ORDERS = 'LOAD_ORDERS';
export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS';
export const DELETE_ORDER = 'DELETE_ORDER';
export const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER';
export const CLEAR_CURRENT_ORDER = 'CLEAR_CURRENT_ORDER';
export const LOAD_CART = 'LOAD_CART';
export const LOADING_CART = 'LOADING_CART';
export const CLEAR_LOADING_CART = 'CLEAR_LOADING_CART';
export const LOAD_ORDER_STATS = 'LOAD_ORDER_STATS';
